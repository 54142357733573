import Menu from "../shared/common/Menu";
import Footer from "../shared/common/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Menu />
      <div className="md:container mb-10">
        <h1 className="text-charcoal text-2xl font-bold mx-8 md:mx-16 my-4">
          Logissy Privacy Policy
        </h1>
        <div className="text-charcoal mt-8 mx-8 md:mx-16">
          <p className="py-2">
            By accessing, using or attempting to access or use the Website
            and/or avail Services from{" "}
            <span className="font-semibold">
              Logissy Pvt Ltd
            </span>
            , a company incorporated under the laws of India with its registered
            office at 488, Royal Park Residency,Alahalli Village, JP Nagar,
            Jayaprakashnagara, Bangalore - 560108 (“Company”) and its
            Affiliates. The Person or entity accessing, using or attempting to
            access or use the Website and/or avail Services from the Logissy Pvt Ltd (“User”), agrees to the following
            terms and conditions of service (“Terms and Conditions”). Services
            in different jurisdictions may be offered by the Affiliates and
            where the User avails such Services from an Affiliate, the Terms and
            Conditions shall also apply to the Services obtained from an
            Affiliate. Any references to a “User” shall include the “Service
            Provider” or the “Customer” or the organization of the User, as the
            context may require.
          </p>
          <p className="py-2">
            Logissy Pvt Ltd offers the Website and
            Services conditioned upon the User’s acceptance of all terms and
            conditions, policies and notices stated here. Please read these
            Terms and Conditions carefully before accessing or using the Website
            and Services provided by the Logissy Pvt Ltd
            Group. Please note that by access to or use of the Website and
            Services, the User also agrees to be bound by such additional terms
            and conditions and policies referenced herein and/or available by
            hyperlink, including without limitation Logissy Pvt Ltd's Privacy Policy, other policies of the
            Logissy Pvt Ltd and the rules applicable to
            each Service.
          </p>
          <p className="py-2">
            The User can review the most current version of the Terms and
            Conditions at any time on this page. The Logissy Pvt Ltd
             reserves the right to update, change or replace
            any part of these Terms and Conditions by posting updates and/or
            changes to Website. It is the User’s responsibility to check this
            page periodically for changes. Following the posting of any changes,
            the User’s continued use of or access to the Website, or Services
            from the Logissy Pvt Ltd, constitutes
            acceptance of such revised Terms and Conditions.
          </p>
          <p className="py-2">
            Under these Terms and Conditions, the Logissy Pvt Ltd
             limits its liability to the User, and the User agrees to
            indemnify the Logissy Pvt Ltd against
            certain losses that the Logissy Pvt Ltd may
            suffer, in connection with the use of the Website, Services by the
            User. These Terms and Conditions include all modifications up to
            date.
          </p>
          <ol className="list-decimal">
            <li>
              <h2 className="text-lg font-semibold">Other applicable terms</h2>
              <p className="py-2">
                These Terms and Conditions refer to, incorporate, and include
                the Privacy Policy of the Logissy Pvt Ltd
                 which sets out the terms on which the Logissy Pvt Ltd
                 process any personal data the Logissy Pvt Ltd
                collects from the User, or that
                the User provides to the Company. By using the Website, Services
                of the Company, the User consents to such processing and the
                User warrants that all data provided by the User is accurate.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">About us</h2>
              <p className="py-2">
                The Logissy Pvt Ltd provides a digital
                platform for shipping, logistics and other allied services that
                connects consignors, consignees and service providers of freight
                services. The various entities of the Logissy Pvt Ltd acts as an intermediary enabling the
                consignors/consignees and service providers to conclude a
                contract with each other. The digital platform is provided on
                the domain name www.logissy.com, including any sub-domains
                (“Website”). The Website is owned by the Company.
              </p>
              <p className="py-2">
                PLEASE NOTE THAT THE LOGISSY PVT LTD
                ENTITIES ACT ONLY AS AN INTERMEDIARY AND AS A FACILITATOR
                BETWEEN THE SERVICE PROVIDER AND CUSTOMER. LOGISSY PVT LTD
                 ENTITIES DO NOT ACT AS A CARRIER, NVOCC,
                FREIGHT FORWARDER, SHIPBROKER, INSURANCE BROKER OR ANY OTHER
                LOGISTICS OR INSURANCE SERVICE PROVIDER OR AN ENTITY UNDER A
                SIMILAR CONCEPT UNDER ANY APPLICABLE LAW. LOGISSY PVT LTD ENTITIES REPRESENT THE USER AS THE
                USER’S AGENT TO CUSTOMS AUTHORITY, GOVERNMENT AGENCY, SHIPPING
                LINES AND OR OTHER GOVERNMENTAL AUTHORITIES. IF THE USER
                PROVIDES ANY INCOMPLETE OR WRONG OR INSUFFICIENT INFORMATION OR
                FAIL TO DISCLOSE IF ANY REQUIRED UNDER APPLICABLE LAW, THEN THE
                USER IS ENTIRELY LIABLE FOR THE ACTS OF THE VARIOUS LOGISSY PVT LTD ENTITIES. THE USER AGREES THAT
                THE USER SHALL INDEMNIFY AND HOLD THE LOGISSY PVT LTD ENTITIES HARMLESS FROM ANY AND ALL CLAIMS,
                LOSSES, PENALTIES OR OTHER COSTS RESULTING FROM THE SAID
                DEFAULT.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">
                Definitions and interpretation
              </h2>
              <p className="py-2">
                Capitalized terms, not defined elsewhere in these Terms and
                Conditions, shall mean as follows:
              </p>
              <p className="py-2">
                (a)  “Affiliates” means entities owned and controlled, directly
                or indirectly, by the Company, where “control” means (a) the
                acquisition or control of more than 50% (fifty percent) of the
                voting rights or of the issued share capital of entity and/or
                (b) the right to appoint and/or remove all or a majority of the
                members of the board of directors or other governing body of
                such entity, and/or (c) the power to direct or cause the
                direction of the management and policies of such entity, whether
                obtained directly or indirectly, and whether obtained by
                ownership of share capital, the possession of voting rights,
                through contract or otherwise;
              </p>
              <p className="py-2">
                (b)  “Applicable Laws” means all applicable (i) statutes,
                enactments, acts of legislature or parliament, laws, bye-laws,
                ordinances, rules, regulations, listing agreements,
                notifications, guidelines or policies of any applicable
                jurisdiction; (ii) administrative interpretation, writ,
                injunction, directions, directives, judgment, arbitral award,
                decree, orders or consents of, or agreements with, any
                Governmental Authority or a recognized stock exchange; and (iii)
                international tax treaties, as may be in force from time to
                time;
              </p>
              <p className="py-2">
                (c)  “Logissy Pvt Ltd” means the
                Company and its Affiliates;
              </p>
              <p className="py-2">
                (d)  “Customer” shall mean any person who is willing to buy
                Services from the Service Providers using the Website for
                transportation of goods (on behalf of themselves or any other
                person).
              </p>
              <p className="py-2">
                (e)  “Fees” means the fee payable to the Logissy Pvt Ltd by the Service Provider and/or the Customer
                in connection with the use of the Services through the Website,
                as specified in writing by the Logissy Pvt Ltd;
              </p>
              <p className="py-2">
                (f)  “Force Majeure Event” shall mean any event that is beyond
                the reasonable control
              </p>
              <p className="py-2">
                (g)  “Governmental Authority” shall mean any nation or
                government or any province, state or any other political
                subdivision thereof; any entity, authority or body exercising
                executive, legislative, judicial, regulatory or administrative
                functions of or pertaining to government, including any
                government authority, agency, department, board, commission or
                instrumentality of India or any other jurisdiction in which the
                Logissy Pvt Ltd or the User conducts
                business.
              </p>
              <p className="py-2">
                (h)  “Services” shall mean the services as more specifically
                displayed on the Website from time to time by the Consigners,
                including without limitation shipping and logistic services,
                associated services including freight insurance or customs
                brokerage services, trade finance and invoice discounting.
              </p>
              <p className="py-2">
                (i)  “Information” is a reference to any information uploaded or
                otherwise provided on the Website by any User, including without
                limitation for the purposes of registration, listing of rates or
                quotes or bidding and other services as provided from time to
                time on the Website.
              </p>
              <p className="py-2">
                (j)  “Person” shall mean a corporation, a partnership, a joint
                venture, a trust, an unincorporated organization and any other
                legal entity.
              </p>
              <p className="py-2">
                (k)  “Service Provider” shall mean any person, including without
                limitation a freight forwarder, broker, carrier, insurance
                company, financial institution, custom housing agent,
                transporter or other professional vendor, who is willing to
                provide Services to the Customers using the Website.
              </p>
              <p className="py-2">
                (l)  “Add-on Services” shall mean the premium services and other
                subscription based services as more specifically displayed on
                the Website from time to time by the Logissy Pvt Ltd, including without limitation procurement of data
                relating to the consignment and calculation of applicable taxes.
              </p>
            </li>
            <li>
              Modifications to these Terms and Conditions: The Logissy Pvt Ltd may, at any time, modify any of the terms of
              this Terms and Conditions and/or the terms of the policies of the
              Logissy Pvt Ltd. The User’s continued use of
              the Website following a modification of this Terms and Conditions
              or a policy constitutes the User’s acceptance of all modifications
              posted on this Website. If the User does not agree to a
              modification, the User may terminate this Terms and Conditions by
              notifying the Logissy Pvt Ltd in writing and/or
              discontinuing the User’s use of the Website.
            </li>
            <li>
              <h2 className="text-lg font-semibold mt-4"> Eligibility:</h2>
              <p className="py-2">
                5.1.  By using the Website, the User represents and warrants
                that –
              </p>
              <p className="py-2">
                (a)  the User is fully able, competent and authorised to enter
                into the terms, conditions, obligations, representations and
                warranties set forth in these Terms and Conditions, including
                where the User is a legal or juridical person or entity;
              </p>
              <p className="py-2">
                (b)  all registration information (including any Information)
                that the User submits is truthful and accurate;
              </p>
              <p className="py-2">
                (c)  the User will maintain the accuracy of such information
                (including any Information);
              </p>
              <p className="py-2">
                (d)  the User has attained the age to legally accept these Terms
                and Conditions as per the Applicable Law applicable to the User
                and/or at the User's location. Where the User represents a legal
                or juridical person or entity, the User confirms that the User
                is duly authorized by such entity to accept these Terms and
                Conditions;
              </p>
              <p className="py-2">
                (e)  all details that the User provides regarding consignment
                and/or delivery shall be true and accurate, and the User
                acknowledges that the Logissy Pvt Ltd
                entities are not required to independently verify the same when
                issuing the goods receipt / consignment note / goods forwarding
                note (either through the Logissy Pvt Ltd
                entity or the Service Providers);
              </p>
              <p className="py-2">
                (f)  the User has not previously been suspended or removed from
                using the Services; and
              </p>
              <p className="py-2">
                (g)  the User of the Website does not violate any Applicable Law
                or regulation. Use of the Website is void where prohibited.
              </p>
              <p className="py-2">
                5.2.  The User is not eligible to use the Website if the User is
                an entity which is a competitor of the Logissy Pvt Ltd, or if the User operates a similar business or
                platform to the Website. The Website is available only to
                genuine Users who are accessing it for their personal or
                business purposes and not for any phishing purposes. The
                Logissy Pvt Ltd reserves the right to
                terminate or restrict any User’s access to the Website if the
                ineligibility of the User is brought to the notice of the
                Logissy Pvt Ltd or if it is discovered that
                such Person or entity is not eligible to use the Website
                pursuant to these Terms and Conditions or Applicable Laws.
              </p>
              <p className="py-2">
                5.3. In case the Logissy Pvt Ltd alters or
                amends the eligibility criteria to be registered for receiving
                the Services and the User no longer complies with the new
                eligibility criteria, as determined by the Logissy Pvt Ltd in its sole discretion, the User accepts that the
                Logissy Pvt Ltd may restrict or suspend the
                access to the Services without any liability for the Logissy Pvt Ltd. The User is solely responsible for
                ensuring that the User is in compliance with these Terms and
                Conditions.
              </p>
              <p className="py-2">
                5.4.  The User shall ensure that there are proper encryption and
                security measures at its respective desktop, mobile device or
                other device used to access the Services to prevent any hacking
                and the User accepts all liabilities with respect to any
                compromise or unauthorized use or disclosure or access to such
                information.
              </p>
              <p className="py-2">
                5.5.  The User agrees and accepts that the User can access or
                avail the Services only for oneself and not on behalf of any
                third party, unless specifically approved by the Logissy Pvt Ltd. In case the User intends to trade on behalf
                of another entity or third party, the User shall intimate the
                Company separately and obtain a prior email approval, after
                which the User may use the Services for the use of only the
                specified third party. If the User uses any Services on behalf
                of any third party, both the User and such other third party
                will be jointly and severally liable under these Terms and
                Conditions for any violation of these Terms and Conditions or
                any other act or omission by the third party or by the User.
              </p>
              <p className="py-2">
                5.6.  The User agrees and accepts that the Logissy Pvt Ltd may enter into an agreement with
                third-party Service Providers for the provision of the Services
                and Add-on Services, and such third-party Service Providers are
                third-party contractors and are not employees or authorized
                personnel of the Logissy Pvt Ltd. The
                Logissy Pvt Ltd is not liable for any
                actions and omissions of or accuracy of data provided by such
                persons, and the User agrees to accept the Services and Add-on
                provided by such persons at the risk of the User. The User shall
                not make any claims or demands against the Company in case of
                any losses, costs, damages, or other charges suffered or
                incurred by the Users in connection with any acts or omissions
                of such persons and/or injury suffered by the User. Further, the
                User shall not attempt to circumvent the Logissy Pvt Ltd's relationship with the personnel in any
                manner whatsoever or encourage others to do so.
              </p>
              <p className="py-2">
                5.7.  The Logissy Pvt Ltd offers
                various Services and Add-on Services to Users in the manner
                specified on the Website which can include trading, broking,
                software, and other services. All such Services and Add-on
                Services shall be continued to be governed by these Terms and
                Conditions. Where the service providers are entities which are
                not the providers of the Website, such services are provided by
                such service providers on a principal-to-principal basis, and
                the Logissy Pvt Ltd does not certify
                the fitness, merchantability, quality, quantity, or fitness for
                specific purposes for these Services or Add-on Services. The
                Logissy Pvt Ltd expressly disclaims
                all liabilities in relation to such Services and Add-on
                Services.
              </p>
            </li>
            <li>
              Trading: The Logissy Pvt Ltd provides
              Services by offering a platform on which Customers and Service
              Providers can conclude contracts of carriage with each other (the
              “Contract”) as follows:
              <p className="py-2">
                6.1. The Service Provider agrees that each quote displayed on
                the Website (by the Logissy Pvt Ltd,
                based on the rates agreed with the Service Provider) shall
                constitute a binding offer by that Service Provider (acting
                through the Logissy Pvt Ltd entity) to
                the Customer for the relevant Services;
              </p>
              <p className="py-2">
                6.2. Where the Customer accepts any quote displayed on the
                Website, the Customer agrees that this constitutes acceptance of
                the Service Provider's binding offer (acting through the
                  Logissy Pvt Ltd entity);
              </p>
              <p className="py-2">
                6.3. Where the Customer provides a counter-offer to the quote
                displayed on the Website, the Service Provider and Customer
                agree that this constitutes a binding counter-offer by that
                Customer to the Service Provider for the relevant Services, and
                where the Service Provider (acting though the Logissy Pvt Ltd) accepts such counter-offer, both the
                Customer and Service Provider agree that this constitutes
                acceptance of the Customer's binding counter-offer, and, in each
                case, the Service Provider's standard terms and conditions
                applicable to that mode of transport shall apply to the
                Contract.
              </p>
              <p className="py-2">
                6.4. The Service Provider and the Customer hereby acknowledge
                and agree that the Logissy Pvt Ltd
                entity is not a party to the Contract, and the Service Provider
                will be solely responsible for performing all obligations under
                the Contract. For the avoidance of doubt, the Logissy Pvt Ltd entities do not act as principal or as a
                (direct, indirect, or contractual) carrier, shipping company,
                freight forwarder, customs broker, insurer, or financial
                institution. However, the Logissy Pvt Ltd entity may, on behalf of the Service Provider, facilitate
                the Contractors and collect payments on behalf of the Service
                Provider. The Customer agrees and acknowledges that the
                Logissy Pvt Ltd has the full right of
                recovery from the Customer in respect to any and all payments
                owed by the Customer pursuant to the use of the Website. The
                Service Provider agrees to execute all necessary documents,
                agreements, attorneys, authorizations, as specified by the
                Logissy Pvt Ltd for the exercise of
                such rights by the Logissy Pvt Ltd.
              </p>
              <p className="py-2">
                6.5. The Users acknowledge that the Logissy Pvt Ltd does not validate, verify or otherwise
                authenticate, certify, confirm, or endorse the Users or the
                Information provided or issued by the User, nor does it endorse
                or guarantee the provision of the Services to the Users or make
                any guarantees as to the performance of Services by the Service
                Provider(s), or payment for such Services by the Customer(s).
                The Logissy Pvt Ltd will not provide
                and/or issue any means of transport, containers, bills of
                lading, waybills, consignment notes, customs declarations, or
                any other documents relating to the provision of the Services to
                the Users. The Logissy Pvt Ltd has no
                control over the adequacy and/or accuracy of the Services or the
                use, safety, and legality of the cargo to be shipped. The
                Logissy Pvt Ltd cannot ensure and does
                not guarantee that a User will actually complete a Contract or
                act lawfully while using the Website, and each User is
                responsible for ensuring compliance with its obligations under
                the Terms and Conditions and the Contract.
              </p>
              <p className="py-2">
                6.6. Nothing in these Terms and Conditions amounts to a
                financial or performance guarantee on behalf of any of the
                Service Providers and the Customers in connection with the
                Contract or otherwise, and the Logissy Pvt Ltd is not liable in any manner whatsoever in connection
                with the performance of any Contract.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Membership</h2>
              <p className="py-2">
                7.1. The Logissy Pvt Ltd reserves the
                right to change the contents and terms of the Website and the
                Services and suspend, terminate, or restrict the Service,
                including the User’s access to the Website, in accordance with
                the terms of these Terms and Conditions or the Logissy Pvt Ltd's policies. If the Logissy Pvt Ltd changes the contents and terms
                of the Website and the Services, or suspend or terminate a
                User’s right to use the Website, the User may be prevented from
                accessing the Website and the Services, either temporarily or
                permanently. If the User’s membership is suspended or
                terminated, the User is prohibited from applying for a new
                membership account with the Logissy Pvt Ltd. The User account (including feedback and any associated
                User identification) may not be dealt with in any way by any
                third parties.
              </p>
              <p className="py-2">
                7.2. The User shall be responsible for maintaining the
                confidentiality and security of the password and for all
                activities that occur in and through the User’s account. As a
                registered User, the User is responsible for maintaining control
                of the User’s account and for any activity that occurs on the
                User’s account. To maintain the safety of the Website and
                Services, the User must report any unauthorized use of the
                User’s account and associated User identification to the
                Logissy Pvt Ltd as soon as possible
                after becoming aware of the unauthorized use through the e-mail
                address provided on the Website at the time of registration.
              </p>
              <p className="py-2">
                7.3. The User acknowledges and agrees that the use of another
                User’s account for availing the Services or the Website is
                expressly prohibited.
              </p>
              <p className="py-2">
                7.4. The User hereby agrees that if the User provides any
                information that is untrue, inaccurate, not current, or
                incomplete (or becomes untrue, inaccurate, not current, or
                incomplete) or if the Logissy Pvt Ltd
                has reasonable grounds to suspect that such information is
                untrue, inaccurate, not current, incomplete, or not in
                accordance with these Terms and Conditions, the Group shall have
                the right to indefinitely suspend or terminate or block access
                to the User’s membership on the Website and refuse to provide
                the User with access to the Website or the Services.
              </p>
            </li>
            <li>
              Records: Logissy Pvt Ltd shall maintain
              complete and accurate records of all Contracts entered into
              between the Users on the Website so that it can be verified by the
              Users. Logissy Pvt Ltd is not liable for
              any loss of data, technical or otherwise, information, or other
              particulars supplied by the User, due to reasons beyond its
              control like corruption of data or delay or failure to perform as
              a result of a Force Majeure Event
            </li>
            <li>
              <h2 className="text-lg font-semibold mt-2">
                Customer's Undertakings
              </h2>
              <p className="py-2">
                9.1 Where the Customer enters into a Contract with a Service
                Provider in accordance with these Terms and Conditions, the
                Customer agrees to be bound by the Service Provider's standard
                terms and conditions applicable to that mode of transport (or
                any other terms as otherwise agreed to between the User and the
                Service Provider).
              </p>
              <p className="py-2">
                9.2. The Customer shall be obliged to fulfill its obligations
                under any Contract entered by the Customer through the Website.
              </p>
              <p className="py-2">
                9.3. At the time of submitting a request for a quote, the
                Customer must describe the requirements for the Services the
                Customer wishes to purchase, fully and accurately. The
                Customer’s listing must only include content relevant to that
                quote.
              </p>
              <p className="py-2">
                9.4. The Customer shall comply with the Applicable Laws
                regarding safety, security, use, and conduct as reasonably
                requested by the Logissy Pvt Ltd or
                the Service Provider.
              </p>
              <p className="py-2">
                9.5. If the Services are not received within the duration
                indicated by the Service Provider or are not in accordance with
                the specifications indicated on the Website, the Customer must
                inform the Company about the same through the Website. Any
                dispute relating to the Contract shall be resolved directly
                between the Customer and the Service Provider, and the Company
                shall, under no circumstance, be a party to the Contract and/or
                be held liable or responsible for the same.
              </p>
              <p className="py-2">
                9.6. The Customer shall be solely responsible for undertaking
                appropriate and effective due diligence and related analysis
                before entering into any Contract. The Customer acknowledges and
                agrees that the Logissy Pvt Ltd has
                not carried out any background checks or other verification
                relating to the Service Provider and that the Logissy Pvt Ltd does not guarantee the quality of
                Services being provided to the Customer.
              </p>
              <p className="py-2">
                9.7. The Customer is solely responsible for coordinating and
                communicating with the Service Provider for the performance of
                the relevant Services, in accordance with these Terms and
                Conditions.
              </p>
              <p className="py-2">
                9.8. By accepting these Terms and Conditions, the Customer
                hereby irrevocably accords its consent for appointing the
                Logissy Pvt Ltd to act as the
                Customer’s agent for the limited purpose indicated in these
                Terms and Conditions.
              </p>
              <p className="py-2">
                9.9. The Customer agrees and accepts that Logissy Pvt Ltd is not responsible in any manner whatsoever
                in respect of the performance of the Contracts or the goods
                provided by the Customer. The Customer shall not make the
                Logissy Pvt Ltd a party to any
                disputes relating to the Contracts and shall defend, indemnify,
                and hold harmless the Logissy Pvt Ltd
                from any claims in this regard.
              </p>
              <p className="py-2">
                9.10. Where the Customer is acting on behalf of any other
                person, the Customer confirms and accepts that the Customer is
                authorized to accept such Contracts. The Customer shall also
                always make sure that such persons shall comply with all
                applicable laws and all other directions of the Company in
                connection with the Services.
              </p>
              <p className="py-2">
                9.11. The customer accepts that Logissy Pvt Ltd verifies the company and stakeholder's details with
                TransUnion CIBIL. (applied for financial services like Ship now
                Pay later, Export factoring, etc.).
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">
                Service Provider’s Undertakings:
              </h2>
              <p className="py-2">
                10.1. The Service Provider warrants and agrees to maintain in
                full force for the duration of the registration on the Website,
                all consents, approvals, authorizations, licenses, orders,
                registrations, clearances, and qualifications (being of a
                governmental, corporate, or other nature) necessary for the
                performance of the Services listed by the Service Provider on
                the Website.
              </p>
              <p className="py-2">
                10.2. The Service Provider shall be required to populate details
                of the Services being offered by the Service Provider on the
                Website and its cost along with such other information as maybe
                required by the Customers.
              </p>
              <p className="py-2">
                10.3. The Service Provider hereby authorizes the Logissy Pvt Ltd to:
              </p>
              <p className="py-2">
                (a) Automate fixed-price quotes based on the rates provided by
                the Service Provider;
              </p>
              <p className="py-2">
                (b) Advertise and promote the Service Provider’s Services on the
                Website; and
              </p>
              <p className="py-2">
                (c) Create a profile page for you to share basic information
                with Customers about the User’s organization, Services offered,
                and reviews.
              </p>
              <p className="py-2">
                10.4. The Service Provider hereby grants to the Logissy Pvt Ltd, a royalty-free, fully paid-up,
                worldwide, non-exclusive license to use the Service Provider’s
                Information, names, logos, and acknowledges and agrees that such
                use by the Company shall not constitute a violation or
                infringement of the intellectual property rights of the Service
                Provider.
              </p>
              <p className="py-2">
                10.5. Other than in the manner as provided under this Terms and
                Conditions or the Logissy Pvt Ltd’s
                Privacy Policy, the Service Provider shall not use Logissy Pvt Ltd’s name, logos, or any other
                intellectual properties without the prior written approval of
                the Logissy Pvt Ltd. Where such use is
                expressly permitted by the Logissy Pvt Ltd, the Service Provider shall adhere to the media usage
                guidelines provided by the Logissy Pvt Ltd from time to time.
              </p>
              <p className="py-2">
                10.6. The Service Provider shall also be required to specify, as
                well as regularly keep up to date, the cost of any additional
                Services such as, inter alia, customs brokerage, insurance, and
                costs for shipment of hazardous materials, carry to the room of
                choice, etc., for inclusion or exclusion by the Customer.
              </p>
              <p className="py-2">
                10.7. The Service Provider shall be obligated to inform
                Logissy Pvt Ltd about the delivery of
                the consignments through the Website.
              </p>
              <p className="py-2">
                10.8. The Service Provider shall be solely responsible for
                undertaking appropriate and effective due diligence and related
                analysis before entering into any Contract. The Service Provider
                acknowledges and agrees that the Company has not carried out any
                background checks or other verification relating to the
                Customer.
              </p>
              <p className="py-2">
                10.9. The Service Provider shall be solely responsible for
                coordinating and communicating with the Customer for the
                performance of any Contract and shall be entirely liable for any
                goods which are a part of the Contract. The Service Provider
                shall not make the Logissy Pvt Ltd a
                party to any disputes relating to the Contract or the underlying
                goods and shall defend, indemnify, and hold harmless from any
                claims in respect of the Contracts or any underlying goods.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">
                Service Provider’s financial terms:
              </h2>
              <p className="py-2">
                11.1.  The Service Provider agrees that a Customer shall make
                the payment due to the Service Provider to the Company, who
                shall aggregate the payments due to such Service Provider from
                various Customers on a monthly basis and make the payment to the
                Service Provider for the previous month within 15 (fifteen) days
                of the subsequent month based on the services provided by the
                Service Provider using the Website.
              </p>
              <p className="py-2">
                11.2.  The fee payable to the Service Provider for the Service
                by a Customer on the Website shall be set based on the agreed
                fees between the Service Provider and the Logissy Pvt Ltd. The Service Provider shall be bound by any
                acceptances made by Customers based on such displayed fee.
              </p>
              <p className="py-2">
                11.3.  The Service Provider warrants and guarantees to the
                Logissy Pvt Ltd that the Service
                Provider is entitled to assign the claims now and in the future,
                and that no other party has any right or title to the claims now
                or in the future unless otherwise agreed between the Service
                Provider and the Logissy Pvt Ltd.
              </p>
            </li>
            <li>
              The Service Provider authorizes the Logissy Pvt Ltd to deduct its Fee for the provision of the
              Services from the payments to be made to the Service Provider for
              the provision of the Services.
            </li>
            <li>
              <h2 className="text-lg font-semibold mt-2">
                Customer’s financial terms:
              </h2>
              <p className="py-2">
                The Customer shall be required to make payments to the Logissy Pvt Ltd for the Services provided by a
                Service Provider under any Contract. For the avoidance of doubt,
                the Customer is obligated to pay the Fee as specified by the
                Company from time to time on the Website for use of the Website
                in addition to the payment for the Services to the Service
                Provider (through the Logissy Pvt Ltd). The Customer shall not pay the Service Provider directly
                unless specifically instructed by the Logissy Pvt Ltd in writing. Such payment shall be made in
                accordance with the terms and payment instructions stated on the
                invoice that the Logissy Pvt Ltd shall
                issue to the User.
              </p>
            </li>
            <li>
              Unless specified otherwise, all invoices must be paid by the
              Customer immediately at the time of receipt of the invoice, and
              any overdue amounts shall carry interest at the rate of 18%
              (eighteen percent) per annum, provided that the Logissy Pvt Ltd, may provide, at its sole discretion and on
              terms specified by Logissy Pvt Ltd,
              provide an extended period for the payment of the invoice. The
              Customer shall bear all charges in connection with the payment of
              any invoices. Without prejudice to the foregoing, the Logissy Pvt Ltd may partner with payment
              aggregators to provide payment collection services and in such a
              case, the User may pay using such services. In such an event, the
              Logissy Pvt Ltd is not liable in any
              manner whatsoever for any defect, malfunction, or other issue with
              such payment aggregator, and the User must contact the payment
              aggregator for any disputes.
            </li>
            <li>
              <h2 className="text-lg font-semibold mt-2">
                Extension of credit terms to Customers and Service Providers:
              </h2>
              <p className="py-2">
                In connection with the payment obligation of the Customers
                and/or to provide supplier financing to the Service Providers,
                the Logissy Pvt Ltd may by itself or
                in partnership with financial institutions, provide credit and
                advances (“Credit Support”) to the Customers and/or the Service
                Providers, as applicable. Such Credit Support shall be provided
                on such terms and conditions as specified by the Logissy Pvt Ltd and/or the financial
                institutions and/or any other person with whom such facility is
                provided. Nothing in these Terms and Conditions shall compel or
                give a right to the Customer and/or Service Provider to demand
                or receive any Credit Support. In connection with such Credit
                Support, the Customer and/or Service Provider shall provide all
                information, details, documents as specified. Any Credit Support
                shall be in accordance with any specific written agreement with
                the Customer for such Credit Support.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Credit check:</h2>
              <p className="py-2">
                The User (as the Customer and/or Service Provider or otherwise)
                irrevocably authorizes the Logissy Pvt Ltd and/or the financial institutions and/or its partners and
                by way of accepting these Terms and Conditions to obtain a
                credit profile, credit score, and other information of the User
                from different sources such as Credit Information Companies,
                including without limitation, CIBIL, Experian, CRIF, and others.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Fraud:</h2>
              <p className="py-2">
                Without prejudice to any other rights and remedies available to
                the Logissy Pvt Ltd at law, in equity
                or under these Terms and Conditions, the Logissy Pvt Ltd may, in the Logissy Pvt Ltd’s sole discretion, suspend or terminate the User’s
                membership if the Logissy Pvt Ltd
                reasonably suspects or believes, or is informed by a government
                agency that the User has engaged in or may engage in fraudulent
                or illegal activity using, or otherwise in connection with such
                User’s use of the Website, and the User will have no claim
                whatsoever against the Logissy Pvt Ltd
                in respect of any suspension or termination of the membership
                under this Clause.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">User listings:</h2>
              <p className="py-2">
                The User is solely responsible for the Information provided by
                the User and User's use of the Website. The User further agrees
                to keep the Information and such other information and/or
                contents posted by such User up to date and in accordance with
                Applicable Law. The Logissy Pvt Ltd
                may remove Information provided by the User from the Website at
                the Logissy Pvt Ltd's sole discretion.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Cancellations:</h2>
              <p className="py-2">
                In the event a shipment does not proceed after a quote is
                accepted on the Website by the Customer, but before any Services
                are performed by the Service Provider, a cancellation is deemed
                to have occurred. In the event of a cancellation, the User may
                be contacted and requested to provide information which confirms
                the basis of the cancellation. This may include written
                confirmation of the cancellation signed by both the Customer and
                the Service Provider, including reasons for the cancellation,
                identification of the party responsible for the cancellation,
                and comments. A Customer cannot request the cancellation of
                Add-on Services during the term of such Add-on Services and
                shall have the option only to prevent the renewal of the term of
                such Add-on Services. A cancellation will entitle the Logissy Pvt Ltd at its discretion to cancel a
                User's membership. Failure to exercise this discretion and
                cancel a User's membership after a cancellation does not
                prejudice the Logissy Pvt Ltd's right
                to cancel a User's membership for a subsequent cancellation. For
                the purpose of this Clause 17, a cancellation is not deemed to
                have occurred if the shipment is delayed or rescheduled at a
                time suitable to both the Customer and the Service Provider.
                Excessive cancellations by a User may impact their rating on the
                Website.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Refunds:</h2>
              <p className="py-2">
                In case the Customer is unable to avail Services from a
                particular Service Provider for any reason whatsoever for no
                fault, default or breach by the Customer, the Logissy Pvt Ltd shall, in its sole discretion, arrange
                for an alternative Service Provider for the provision of
                Services or refund the amount paid by the Customer or take such
                other actions as it may deem fit. In case the Customer is unable
                to avail Add-on Services for any reason whatsoever for no fault,
                default or breach by the Customer, the Logissy Pvt Ltd shall, in its sole discretion, recharge the
                amount paid by the Customer to the Customer's account or take
                such other actions as it may deem fit. However, no refunds shall
                be provided by the Logissy Pvt Ltd in
                relation to the Add-on Services.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Feedback:</h2>
              <p className="py-2">
                Any feedback provided by any User on the Website is
                non-confidential and is owned solely by the Logissy Pvt Ltd. The Logissy Pvt Ltd shall own exclusive rights, including all intellectual
                property rights, and shall be entitled to the unrestricted use
                and dissemination of the feedback for any lawful purpose,
                commercial or otherwise, without acknowledgment or compensation
                to the User. The User hereby waives all moral rights to any such
                feedback, and the User hereby warrants that any such feedback is
                original or that you have the right to submit such feedback. The
                User agrees that there shall be no recourse against the
                Logissy Pvt Ltd for any alleged or
                actual infringement or misappropriation of any proprietary right
                in feedback provided by the User. The User must not do or omit
                to do anything that will or is likely to undermine the integrity
                of the feedback system. Feedback must not be defamatory and must
                not be false or misleading. The User further represents and
                warrants that while posting feedback on the Website, the User
                shall not use any offensive, libelous, derogatory, hateful or
                racially or ethnically objectionable language or is obscene,
                pornographic, or constitutes an indecent representation of any
                person.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Disputes:</h2>
              <p className="py-2">
                The Logissy Pvt Ltd shall not be
                responsible for resolving disputes between the Service Providers
                and Customers and the Logissy Pvt Ltd
                will not resolve or attempt to resolve disputes between the
                Service Providers and Customers. The User agrees that if the
                User has a dispute with one or more Users, all disputes must be
                resolved between such Users. The Logissy Pvt Ltd reserves the right to share the User’s contact
                information with another User and/or any other governmental or
                regulatory authority at the Logissy Pvt Ltd’s sole discretion to the extent that there is a dispute
                between the Users or the User has breached the Applicable Law or
                the Company is required to share information on the User in
                accordance with the Applicable Law. The User hereby irrevocably
                and unconditionally releases the Logissy Pvt Ltd from all claims, demands, indemnifications, and
                damages (actual and consequential) arising out of or connected
                with the dispute or the Logissy Pvt Ltd’s release of the User’s contact information. Any dispute
                between the User and the Logissy Pvt Ltd (including with respect to the provision of Add-on
                Services) should be reported to the Logissy Pvt Ltd via email at{" "}
                <a
                  href="mailto:support@logissy.com"
                  className="underline text-blue"
                >
                  support@logissy.com
                </a>
                &nbsp;to enable the Logissy Pvt Ltd to
                attempt to resolve the dispute.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Communications:</h2>
              <p className="py-2">
                The Logissy Pvt Ltd will send
                communications and notices to the User in relation to any
                activity on the User’s account on the Website and any Contract
                concluded by the User. These communications are not an
                endorsement, guarantee, or a legitimization of any such
                Contract. The User shall be responsible for completing all
                Contracts the User is a party to, which includes complying with
                all relevant legal obligations including without limitation the
                User’s legal obligations in relation to the purchase of the
                Services and the shipping of any cargo.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Prohibited activities:</h2>
              <p className="py-2">
                As a user of the Website, the User agrees not to:
              </p>
              <p className="py-2">(a) Undertake any unauthorized activity;</p>
              <p className="py-2">
                (b) Modify, adapt, or otherwise alter the Website;
              </p>
              <p className="py-2">
                (c) Interfere, in any way, with the listings of other Users;
              </p>
              <p className="py-2">
                (d) Provide false, misleading, deceptive, or otherwise
                inaccurate information;
              </p>
              <p className="py-2">
                (e) Engage in any activities that may result in the price of the
                Services being manipulated;
              </p>
              <p className="py-2">
                (f) Copy, reproduce, create derivative works, or publicly
                display any content contained in the Website (with the exception
                of the User’s information);
              </p>
              <p className="py-2">
                (g) Infringe any third party’s copyright, patent, trademarks,
                confidential information, or any other proprietary/intellectual
                property rights such party may have;
              </p>
              <p className="py-2">
                (h) Take any action that will result in an unreasonable or
                disproportionately large load being imposed on the Logissy Pvt Ltd’s infrastructure;
              </p>
              <p className="py-2">
                (i) List any item on the Site, or complete any Contract that was
                initiated using the website, if payment of the Fee is likely to
                cause us to violate an Applicable Law, this Terms and
                Conditions, or any of the Logissy Pvt Ltd’s policies;
              </p>
              <p className="py-2">
                (j) Knowingly be involved in the shipping of counterfeit, stolen
                items and/or restricted and/or forbidden cargo;
              </p>
              <p className="py-2">
                (k) Violate any Applicable Law or policy (including but not
                limited to those governing export and import control, consumer
                protection, unfair competition, and criminal law);
              </p>
              <p className="py-2">
                (l) Refer to any website or URL that, in the Company's sole
                discretion, contains material that is inappropriate for the
                Website or any other website, contains content that would be
                prohibited or violates the letter or spirit of this Terms and
                Conditions;
              </p>
              <p className="py-2">
                (m) Use any robot, spider, other automatic device or manual
                process to monitor or copy the Website or content contained
                uploaded on the Website;
              </p>
              <p className="py-2">
                (n) Remove, disable, defeat, or otherwise circumvent any
                functionality of the Website (including a requirement to use a
                user name and password or other authentication method, or other
                security protection or preventative measures against
                unauthorized use);
              </p>
              <p className="py-2">
                (o) Damage, disrupt, disable or otherwise impair the operation
                of the Website or interfere with the use of the Website by other
                Users, including introducing a virus, trojan horse or other
                malware into the Website intended to cause damage, breach,
                malfunction, or other undesired effects to the Website, or carry
                out a denial of service attack;
              </p>
              <p className="py-2">
                (p) Collude with, enter an agreement with or correspond with
                another User or other person to avoid paying the Logissy Pvt Ltd the applicable Fee;
              </p>
              <p className="py-2">
                (q) Systematically retrieve data or other content from the Site
                to create or compile, directly or indirectly, a collection,
                compilation, database
              </p>
              <p className="py-2">
                (r) Trick, defraud, or mislead the Logissy Pvt Ltd and other Users, especially in any attempt to learn
                sensitive account information such as user passwords;
              </p>
              <p className="py-2">
                (s) Engage in any automated use of the system, such as using
                scripts to send comments or messages, or using any data mining,
                robots, or similar data gathering and extraction tools;
              </p>
              <p className="py-2">
                (t) Use any information obtained from the Website in order to
                harass, abuse, or harm another person.
              </p>
              <p className="py-2">
                (u) Use the Website as part of any effort to compete with the
                Logissy Pvt Ltd Group or otherwise use the
                Website and/or the content for any revenue-generating endeavor
                or commercial enterprise;
              </p>
              <p className="py-2">
                (v) Decipher, decompile, disassemble, or reverse engineer any of
                the software or algorithms comprising or in any way making up a
                part of the Website;
              </p>
              <p className="py-2">
                (w) Attempt to bypass any measures of the Website designed to
                prevent or restrict access to the Website, or any portion
                thereof;
              </p>
              <p className="py-2">
                (x) Disparage, tarnish, or otherwise harm, in the Logissy Pvt Ltd’s opinion, the Logissy Pvt Ltd Group and/or the Website.
              </p>
              <p className="py-2">
                The Logissy Pvt Ltd Group reserves the right to
                terminate the Terms and Conditions with immediate effect in
                relation to the User who does or is suspected of doing any of
                the above.
              </p>
            </li>
            <li className="my-2">
              License: The User agrees to grant Logissy Pvt Ltd
              an irrevocable, non-exclusive, perpetual, royalty-free,
              sub-licensable, worldwide license to do all acts comprised in the
              copyright and to exercise the publicity and database rights the
              User has in the information, in any media, with respect to the
              Information. Logissy Pvt Ltd will use the
              Information only in accordance with Logissy Pvt Ltd’s Privacy Policy.
            </li>
            <li className="my-2">
              Confidential information: Unless otherwise agreed to by Logissy Pvt Ltd in writing, the User must keep
              confidential, and not use or disclose, any of the information
              (including material obtained through the Website and the User’s
              user name and password for the Website) that is marked as
              confidential information or which is by its nature is confidential
              information (“Confidential Information”), excluding information
              which is public knowledge (other than as a result of breach of
              confidentiality by the User), is in the User’s prior possession or
              that is otherwise acquired or developed by the User independently.
              Upon Logissy Pvt Ltd’s written request, the User
              must return or destroy all copies of Confidential Information in
              the User’s custody or control.
            </li>
            <li className="my-2">
              Non-identifiable User data: Logissy Pvt Ltd shall
              be entitled to obtain and aggregate technical and other data about
              the User’s use of the Website and the Services that are
              non-personally identifiable with respect to the User, whether as a
              Customer or a Service Provider (“Aggregated Anonymous Data”).
              Logissy Pvt Ltd shall further be entitled to use
              the Aggregated Anonymous Data to improve, support and operate the
              Website and/or facilitate the Services, provided that the User
              will not be identified as the source of any Aggregated Anonymous
              Data and no personal information will be collected as a part of
              Aggregate Anonymous Data. The Company may also sell certain
              reporting and access to documentation. In return, the Company may
              earn service fees and revenue through advertisement and/or Service
              Providers paying a commission/usage fees/referral fees to
              Logissy Pvt Ltd for each Contract they enter into
              through the Website.
            </li>
            <li className="my-2">
              Privacy: Logissy Pvt Ltd will use User’s personal
              information in the manner set out in Logissy Pvt Ltd’s privacy policy (“Privacy Policy”).
            </li>
            <li className="my-2">
              Intellectual property: The Website includes material which may be
              protected by copyright, trademark, or other intellectual property
              laws in India or elsewhere and all rights in relation to such
              material are owned by or licensed to Logissy Pvt Ltd unless otherwise stated. The User hereby acknowledges that the
              Website and the Services constitute original works and have been
              developed, compiled, prepared, revised, selected, and arranged by
              Logissy Pvt Ltd through the application of
              methods and standards of judgment developed and applied through
              the expenditure of substantial time, effort, and money and
              constitutes valuable intellectual property of Logissy Pvt Ltd and other persons (as applicable). The User thereby
              agrees to protect the proprietary rights of Logissy Pvt Ltd during and after the term of these Terms and
              Conditions. The User shall not selectively download portions of
              the Website without retaining the copyright notices. The User may
              be permitted to download material from the Website only for the
              purpose contemplated under this Terms and Conditions. Any
              infringement may lead to appropriate legal proceedings against the
              User at the appropriate forum, at Logissy Pvt Ltd’s sole discretion, for seeking all available remedies under
              the Applicable Laws.
            </li>
            <li>
              Warranty: The User warrants to Logissy Pvt Ltd
              that:
              <p className="py-2">
                (a)  The User has the right and authority to bind the body
                corporate to these Terms and Conditions;
              </p>
              <p className="py-2">
                (b)  All of the information provided by the User in respect of
                these Terms and Conditions is true, accurate and complete and
                Logissy Pvt Ltd may rely on this information;
              </p>
              <p className="py-2">
                (c)  The CIBIL/CBL score and other information relating to
                credit worthiness of the User provided by the User to Logissy Pvt Ltd is true, accurate and complete and
                Logissy Pvt Ltd may rely on this information;
              </p>
              <p className="py-2">
                (d)  The User’s membership details are correct, and the User
                shall timely update the membership details on the Website if
                they change;
              </p>
              <p className="py-2">
                (e)  In respect of any quote requested by a Customer and posted
                on the Website, neither the Customer or Service Provider will
                not approach the other to make any offer or otherwise negotiate
                that quote unless they do so via the Website;
              </p>
              <p className="py-2">
                (f)  The User will not post defamatory comments on the Website;
              </p>
              <p className="py-2">
                (g)  The User has conducted its independent due diligence due
                diligence on the Website about the functions and operation of
                the Website and has not relied on any representations made by
                Logissy Pvt Ltd or any other person in relation
                to the Website;
              </p>
              <p className="py-2">
                (h)  The User will not infringe or permit or assist any other
                person to infringe the intellectual property rights owned by
                Logissy Pvt Ltd from time to time and use best
                efforts to protect and prevent such intellectual property rights
                from such infringement by unauthorized third parties; and
              </p>
              <p className="py-2">
                (i)  The User will comply with all of the User’s obligations
                under all Applicable Laws.
              </p>
            </li>
            <li className="my-2">
              Access: Notwithstanding the User’s acceptance of these Terms and
              Conditions, Logissy Pvt Ltd is under no
              obligation to provide access to the Website or guarantee its
              availability or operation in any form and may suspend or terminate
              access to the Website or make changes to the Website at any time
              without notice to the User.
            </li>
            <li className="my-2">
              Access method: Logissy Pvt Ltd may require the
              User to use a user name and password or other authentication or
              security method in order to access and use the Website. The User
              must keep such user name and password secure at all times.
            </li>
            <li className="my-2">
              No agency: Entry into these Terms and Conditions does not give
              rise to an agency, partnership, joint venture, employee-employer
              or other similar relationship in whatsoever nature. The User does
              not have any other authority to bind Logissy Pvt Ltd or Logissy Pvt Ltd’s related entities or
              affiliates in any way.
            </li>
            <li className="my-2">
              Liability: The User shall be responsible for all claims, losses,
              damages arising out of or in relation to the User’s access and use
              of the Website or the Services. Each act or omission in the course
              of accessing the Website is deemed to have been performed by the
              User, regardless of whether that act or omission was undertaken by
              the User.
            </li>
            <li className="my-2">
              Notice: The User must immediately notify Logissy Pvt Ltd if the User becomes aware of any suspected or
              actual (i) unauthorized activity; or (ii) prohibited disclosure or
              use of the User’s user name and password, or any Confidential
              Information; or (iii) existence or exploitation of any security
              vulnerability, weakness, or threat on the Website. The User must
              take all steps reasonably within the User’s power to mitigate,
              prevent or stop any such conduct and provide all assistance
              reasonably requested by Logissy Pvt Ltd in
              relation to any actions or proceedings Logissy Pvt Ltd may take against any person for any such conduct.
            </li>
            <li>
              Termination: The User may terminate the User’s account with
              Logissy Pvt Ltd by providing Logissy Pvt Ltd with 30 (thirty) days’ written notice of the User’s
              decision to terminate the use of the Website.
            </li>
            <li>
              <p className="py-2">Disclaimers:</p>
              <p className="py-2">
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, Logissy Pvt Ltd MAKES NO REPRESENTATION OR WARRANTY (EITHER
                EXPRESS, IMPLIED OR STATUTORY) ABOUT: THE WEBSITE; THE ACCURACY,
                VERACITY, TIMELINESS OR CONTENT OF ANY INFORMATION OR DATA
                CONTAINED ON THE WEBSITE AND ANY OTHER ASSOCIATED WEBSITE;
                CONTINUED ACCESS TO OR USE OF THE WEBSITE; AVAILABILITY OF THE
                WEBSITE; BACKUP OR BUSINESS CONTINUITY IN RESPECT OF THE
                WEBSITE; THE MERCHANTABILITY, FITNESS, QUALITY OR SUITABILITY
                FOR A PARTICULAR PURPOSE OF THE WEBSITE OR SERVICES OR
                INFORMATION ON THE WEBSITE; ANY SUPPLY AGREEMENTS, PANEL
                AGREEMENTS OR ANY GUARANTEE OF BUSINESS WITH Logissy Pvt Ltd; OR THE WEBSITE OR INFORMATION BEING ERROR OR
                MALICIOUS CODE FREE, SECURE, CONFIDENTIAL OR PERFORMING AT ANY
                PARTICULAR STANDARD OR HAVING ANY PARTICULAR FUNCTION.
              </p>
              <p className="py-2">
                Logissy Pvt Ltd PROVIDE THE WEBSITE ON AN “AS
                IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, AND DO NOT
                WARRANT THAT IT WILL BE AVAILABLE FOR USE AT ALL TIMES.
              </p>
            </li>
            <li>
              <p className="py-2">Limitation of liability:</p>
              <p className="py-2">
                38.1  The Logissy Pvt Ltd will not be liable
                for damage in any form (including, but not limited to, loss of
                data, indirect damages, consequential loss, pure economic loss,
                loss of opportunity, loss of revenue or profit, wrongful death,
                personal injury, property damage, penalties and fines, costs or
                any other loss) sustained by the User, the User’s servants,
                agents, employees, insurers and any other third parties for whom
                the User is or shall be liable pursuant to any legal
                relationship, unless the User proves that the damage is a direct
                result of the Logissy Pvt Ltd's gross
                negligence or wilful misconduct.
              </p>
              <p className="py-2">
                38.2  Notwithstanding clause 34.1., the Logissy Pvt Ltd shall not be liable for any loss or damage of,
                and for any costs incurred by a User, carriers, owners and
                charterers of means of transport (including – but not limited to
                – vessels) or other third parties in relation to any Contract
                and/or Services performed.
              </p>
              <p className="py-2">
                38.3.  The Logissy Pvt Ltd’s collective
                liability for any damage, loss or claim, shall be limited to an
                amount of USD 500 (United States Dollars five hundred) per event
                or series of events with the same cause with a maximum annual
                aggregate liability equal to the amount of USD 5,000 (United
                States Dollars five thousand).
              </p>
              <p className="py-2">
                38.4  Every claim against the Logissy Pvt Ltd
                in relation to the Website and/or the Services (if any) shall
                become time-barred after 12 (twelve) months, unless the
                Logissy Pvt Ltd has agreed upon a
                time-extension in writing. The term shall commence on the day
                following the day on which the claim has become due and payable,
                or the day following the day on which the User had acknowledged
                such claim/loss.
              </p>
            </li>
            <li>
              <p className="py-2">Indemnity:</p>
              <p className="py-2">
                39.1  The User shall immediately and fully indemnify Logissy Pvt Ltd in the event that another User or a third
                party claims any loss or damage or costs in relation to the
                User’s use of the Website, any breach or infringement of any
                rights or terms in these Terms and Conditions and any other
                default, unlawful act or omission on the part of the User. This
                indemnification shall also include reasonable attorney’s fees
                and any other legal costs incurred by Logissy Pvt Ltd or its insurer, employee, agent or servant for
                defending any such claim from another user or a third party.
              </p>
              <p className="py-2">
                39.2  Without limiting the foregoing, the User agrees to
                indemnify Logissy Pvt Ltd, its officers,
                employees and agents from and against any claim, liability,
                loss, damage, costs (including the cost of any settlement and
                legal costs and expenses on a solicitor and own client basis),
                and expenses incurred by virtue of any breaches of a third
                party's intellectual property rights as a direct or indirect
                consequence of the User’s use of the Website or any software or
                other intellectual property licensed to the User under these
                Terms and Conditions.
              </p>
              <p className="py-2">
                39.3  This clause shall survive the termination of these Terms
                and Conditions.
              </p>
            </li>
            <li className="my-2">
              Governing law: These Terms and Conditions and the legal
              relationship between Logissy Pvt Ltd and the User
              shall exclusively be governed by the laws of Singapore.
            </li>
            <li className="my-2">
              Arbitration: All disputes arising out of or in connection with
              these Terms and Conditions and the legal relationship between
              Logissy Pvt Ltd and the User shall be referred to
              arbitration in Singapore. The reference shall be to a sole
              arbitrator and shall be conducted in accordance with the Singapore
              International Arbitration Centre. Terms current at the time when
              the arbitration proceedings are commenced. The language of the
              arbitration shall be English.
            </li>
            <li className="my-2">
              Conflicts: These Terms and Conditions shall be read in conjunction
              with any specified written agreement entered into with the User
              for inter alia use of the Platform and the Services. In case of
              any inconsistency or conflict between the terms of such agreement
              and this Terms and Conditions, the terms of such specific
              agreement shall prevail.
            </li>
            <li className="my-2">
              Severance: Where any provision (or part) of these Terms and
              Conditions is held to be illegal or unenforceable, it may be
              severed, and shall in no way affect or prejudice the
              enforceability of the other terms or conditions of these Terms and
              Conditions.
            </li>
            <li>
              Contact: Any notices, complaints or queries in relation to the
              Website or these Terms and Conditions must be directed to:
              <p className="py-2">
                <a
                  href="mailto:support@logissy.com"
                  className="underline text-blue"
                >
                  support@logissy.com{" "}
                </a>
              </p>
              <p className="py-2">
                In case of notice to Logissy Pvt Ltd:
              </p>
              <p className="py-2">
                488, Royal Park Residency, Alahalli Village, JP Nagar,
                Jayaprakashnagara, Bangalore – 560108
              </p>
            </li>
            <li>
              The User acknowledges that Logissy Pvt Ltd shall
              have the right to seek information pertaining to the User if such
              information is required to be furnished to a regulatory authority
              and other third parties including, inter-alia, the Reserve Bank of
              India, banks, etc. in accordance with Applicable Law.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}
