import LogissyLogoIcon from "../../../assets/icons/logissy-logo-icon.svg";
import BlueUnderlineIcon from "../../../assets/icons/blue-underline.svg";
import Shipper from "../../../assets/logo/shipper.png";
import FreightForwarder from "../../../assets/logo/freight-forwarder.png";
import Carrier from "../../../assets/logo/carrier.png";

export default function CharacteristicsSection() {
  return (
    <div className="p-4 md:container lg:px-24 mt-8 mx-auto">
      <div className="lg:pt-8 lg:pb-4 pt-4 pb-1 justify-center ml-auto mr-auto bg-white rounded-2xl shadow-lg">
        <div className="md:flex justify-center items-center text-center">
          <h1 className="text-[36px] md:text-[56px] font-bold">Make it </h1>
          <div>
            <h1 className="text-[36px] md:text-[56px] font-bold text-blue ml-2 md:mt-4">
              Easy
            </h1>
            <img
              src={BlueUnderlineIcon}
              alt="Blue Underline"
              className="w-24 md:w-auto relative right-0 left-20 md:left-2 md:mt-1 mr-auto ml-14 lg:mx-auto md:mx-0"
            />
          </div>
          {/* <h1 className="hidden md:block text-[56px] font-bold ml-2">with</h1>
          <img
            src={LogissyLogoIcon}
            alt="Logissy Logo"
            className="md:ml-1 md:mr-0 mx-auto mt-2 md:w-60 h-11 md:h-14"
          /> */}
        </div>
        <div className="flex flex-col md:flex-row justify-center gap-8 mt-6 mb-8">
          <div className="flex flex-col items-center">
            <div className="w-60 h-60 rounded-full overflow-hidden bg-lightCyanBlue">
              <img
                src={Shipper}
                alt="Shipper"
                className="w-full h-full object-cover mt-5"
              />
            </div>
            <p className="text-2xl font-bold mt-6 text-center text-charcoal tracking-tighter">
              Shippers
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-60 h-60 rounded-full overflow-hidden bg-lightCyanBlue">
              <img
                src={FreightForwarder}
                alt="Freight Forwarder"
                className="w-full h-full object-cover mt-8"
              />
            </div>
            <p className="text-2xl font-bold mt-7 text-center text-charcoal tracking-tighter">
              Freight Forwarders
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-64 h-64 rounded-full overflow-hidden border-solid border-1 border-lightBlueGrey">
              <img
                src={Carrier}
                alt="Carrier"
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-2xl font-bold mt-3 text-center text-charcoal tracking-tighter">
              Carriers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
